<template>
  <div>
    <vs-input class="w-full mt-4" label="Booking Email Label" placeholder="eg. Email Booking Team" v-model="form.bookingEmailName" v-validate="'max:50'" name="bookingEmailName" />
    <span class="text-danger text-sm">{{ errors.first('bookingEmailName') }}</span>

    <vs-input class="w-full mt-4" label="Booking Email Address" placeholder="eg. sales@domain.com" v-model="form.bookingEmail" v-validate="'max:100|email'" name="bookingEmail" />
    <span class="text-danger text-sm">{{ errors.first('bookingEmail') }}</span>

    <vs-input class="w-full mt-4" label="Booking Telephone Label" placeholder="eg. Call Booking Team" v-model="form.bookingTelephoneName" v-validate="'max:150'" name="bookingTelephoneName" />
    <span class="text-danger text-sm">{{ errors.first('bookingTelephoneName') }}</span>

    <vs-input class="w-full mt-4 mb-8" label="Booking Telephone Number" placeholder="eg. 0123456789" v-model="form.bookingTelephone" v-validate="'max:20'" name="bookingTelephone" />
    <span class="text-danger text-sm">{{ errors.first('bookingTelephone') }}</span>

    <div class="lg:float-left mt-4">
      <span class="text-sm text-danger">*Required Field</span>
    </div>
    <div class="flex flex-wrap justify-end">
      <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
    </div>
  </div>
</template>

<script>
import companyApplicationStore from '../companyApplicationStore'
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'location-settings',
  data () {
    return {
      form: {},
    }
  },
  props: {
    applicationId: { required: true }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
      // If user hasn't gone to the user list yet we need to fetch user list
      // this.$store.dispatch('companyApplicationStore/getGeneralSettings', this.$route.params.companyId )
      //     .catch(err => console.error(err) )
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))
    
    this.form = new Form({
      bookingEmailName: appDetailsCopy.bookingEmailName,
      bookingEmail: appDetailsCopy.bookingEmail,
      bookingTelephoneName: appDetailsCopy.bookingTelephoneName,
      bookingTelephone: appDetailsCopy.bookingTelephone,
    })

  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.put(`api/applications/${this.applicationId}/bookingSettings`)
            .then(response => { 
              this.$store.dispatch('companyApplicationStore/updateActiveApplication', this.form.data())
              this.$_notifySuccess('Successfully updated application settings')
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleReset () {
    },
    handleCancel () {
      this.$router.push(`/companies/${this.$route.params.companyId}/apps`)
    }
  }
}
</script>
<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }
</style>
