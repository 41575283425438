<template>
  <div class="app-setting-field-arraylinks">
    <label class="text-sm">{{ settings.title }}</label>
    <ag-grid-vue
      v-show="value && value.length && value.length > 0"
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material w-100"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      :rowDragManaged="true"
      :animateRows="true"
      :context="context"
      :headerHeight="0"
      :rowHeight="rowHeight"
      :domLayout="domLayout"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>
    
    <div class="vx-col w-full">
      <vs-button @click="onAddFile" class="w-full" color="primary">{{ addButtonCaption }}</vs-button>
    </div>
    
    <travio-modal v-if="showAddModal && newFile" width='700' height="200">
      <h3 slot="header">{{'Add ArrayLink'}}</h3>
      <div slot="body">
        <vs-input 
          class="w-full mt-4"
          label="Text"
          v-validate="'required'"
          v-model="newFile.Text"
          name="New Text"
          key="add-text" />
        <span class="text-danger text-sm">{{ errors.first("New Text") }}</span>
        <vs-input class="w-full mt-4" 
          label="Url" 
          v-validate="{required: true, url: {require_protocol: false }}"
          v-model="newFile.Url"
          name="New Url"
          key="add-url"  />
        <span class="text-danger text-sm">{{ errors.first("New Url") }}</span>
      </div>
      
      <div slot="footer" class="flex flex-wrap justify-center">
        <vs-button class="" color="primary" @click="onSaveAdd" type="filled">Save</vs-button>      
        <vs-button class="ml-2" color="danger" @click="onCancelAdd" type="filled">Cancel</vs-button>      
      </div>
    </travio-modal>

    <travio-modal v-if="showEditModal" width='700' height="250">
      <h3 slot="header">Edit Array Link</h3>
      <div slot="body">
        <vs-input class="w-full mt-4" label="Text" 
          v-validate="'required'"
          v-model="rowData[editingRowIndex].Text" 
          name="Text" 
          key="edit-text" />
        <span class="text-danger text-sm">{{ errors.first("Text") }}</span>
        <vs-input class="w-full mt-4" label="Url" 
          v-validate="{required: true, url: {require_protocol: false }}"
          v-model="rowData[editingRowIndex].Url" 
          name="Url"
          key="edit-url" />
        <span class="text-danger text-sm">{{ errors.first("Url") }}</span>

      </div>
      
      <div slot="footer" class="flex flex-wrap justify-center">
        <vs-button class="" color="primary" @click="onSaveEdit" type="filled">Save</vs-button>      
        <vs-button class="ml-2" color="danger" @click="onCancelEdit" type="filled">Cancel</vs-button>      
      </div>
    </travio-modal>
    
  </div>
</template>

<script>
import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import CellRendererImageSetActions from '../../cell-renderers/CellRendererImageSetActions.vue'


export default {
  components: {
    TravioModal,
    CellRendererImageSetActions,
    AgGridVue
  },
  
  props:{
    value: { required: true },
    settings: { required: true }
  },
  data () {
    return {
      rowData: [],
      showMainPopup: false,
      showAddModal:false,
      showEditModal: false,

      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      cellRendererComponents: {
        CellRendererImageSetActions
      },
      context: null,
      // Makes the grid height adjust based on number of records
      domLayout: 'autoHeight',
      rowHeight: null,
      newFile: { Text: '', Url: '', ArtificialId: 0 },
      editFile: { Text: '', Url: '', ArtificialId: 0 },
      editingRowIndex: null,
      addButtonCaption: 'Add'
    }
  },
  computed: {
  },
  beforeMount() {
    this.gridOptions = {};

    this.columnDefs =  [
      { field: 'Url', hide: true },
      { headerName: 'Text', field: 'Text', width: 280, rowDrag: true, suppressSizeToFit: false },
      { headerName: 'Action', suppressSizeToFit: true, width: 80, cellRendererFramework: Vue.extend(CellRendererImageSetActions) }
    ]

    this.rowHeight = 36;
    this.defaultColDef = {
      flex: 1,
      minWidth: 50,
      //important to set this to false since rowDrag is enabled
      sortable: false,
      resizable: true,
      suppressMenu: true
    }
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }

  },
  mounted () {
    this.gridApi = this.gridOptions.api || {};
    this.gridColumnApi = this.gridOptions.columnApi || {};
    this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit();
    this.gridOptions.onRowDragEnd = (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.rowData, x => x.ArtificialId == rowNodeGettingDragged.data.ArtificialId)
      const toIndex = e.overIndex
      array_move(this.rowData, fromIndex, toIndex)

      
      this.rowData = JSON.parse(JSON.stringify(this.rowData))
      // Remove Artificial id before emitting value, since not part of the schema
      const outputValue = JSON.parse(JSON.stringify(this.rowData)).map(x => {
        delete x.ArtificialId
        return x
      })

      this.$emit('input', JSON.stringify(outputValue))

    }
  },
  methods: {
    onSaveAdd (value) {
       this.$validator.validateAll()
      .then(result => {
        if(result) {
          this.rowData.push(this.newFile)
          this.rowData = JSON.parse(JSON.stringify(this.rowData))
          // Emitting a copy to prevent binding to parent
          const outputValue = JSON.parse(JSON.stringify(this.rowData)).map(x => {
            delete x.ArtificialId
            return x
          })
          this.$emit('input', JSON.stringify(outputValue))

          this.resetCurrentFile()
          // Rebuild settings data since emitting input, updates this.value
          
          this.showAddModal = false
          // Make the currently visible columns fit the screen
          this.gridApi.sizeColumnsToFit()
        } else {
          this.$_notifyFailure('Invalid fields')
        }
      })
      .catch(error => this.$_notifyFailure('Invalid fields'))
      
    },
    onSaveEdit (value) {
      this.$validator.validateAll()
      .then(result => {
        if(result) {
          if (this.editingRowIndex === null) return
          this.rowData[this.editingRowIndex] = Object.assign({},this.rowData[this.editingRowIndex])
          this.rowData = JSON.parse(JSON.stringify(this.rowData))

          const outputValue = JSON.parse(JSON.stringify(this.rowData)).map(x => {
            delete x.ArtificialId
            return x
          })

          //Emitting a copy to prevent binding to parent
          this.$emit('input', JSON.stringify(outputValue))
          this.editingRowIndex = null
          this.showEditModal = false
          // Make the currently visible columns fit the screen
          this.gridApi.sizeColumnsToFit()
        } else {
          this.$_notifyFailure('Invalid fields')
        }
      })
      .catch(error => this.$_notifyFailure('Invalid fields'))
    },
    onCancelAdd () {
      this.resetCurrentFile()
      this.showAddModal = false
    },
    onCancelEdit () {
      this.resetCurrentFile()
      this.showEditModal = false
    },
    resetCurrentFile () {
      this.newFile = { Text: '', Url: '', ArtificialId: 0 }
    },
    onGridReady () {
      if (this.value) {
        let rowData = JSON.parse(this.value)
        rowData.forEach((row, index) => {
          row.ArtificialId = index
          // In popup widget, type is saved as code when selected from drop-down
        });
        this.rowData = JSON.parse(JSON.stringify(rowData))
      }
      // this.gridApi.sizeColumnsToFit()
      
    },
    onAddFile () {
      //dynamically create as settings value for the add popup
      const maxArtificialId = Math.max.apply(Math, this.rowData.map(o => o.ArtificialId))
      this.newFile.ArtificialId = maxArtificialId + 1
      this.newFile = Object.assign({}, this.newFile)
      this.showAddModal = true
    },
    onEditImage (image, rowIndex) {
      //Image has no id so we just rely on the row index
      this.editingRowIndex = rowIndex
      this.showEditModal=true
    },
    onDeleteImage (image, rowIndex) {

      this.rowData.splice(rowIndex, 1) 
      this.rowData = JSON.parse(JSON.stringify(this.rowData))
      
      const outputValue = this.rowData.map(x => {
        delete x.ArtificialId
        return x
      })
      //Emitting a copy to prevent binding to parent
      this.$emit('input', JSON.stringify(outputValue))
      // Make the currently visible columns fit the screen
      this.gridApi.sizeColumnsToFit()
    },
    isValidURL(str) {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(str);
    },
  }
}

</script>

<style>
.app-setting-field-arraylinks .ag-theme-material .ag-cell{
  padding-left: 3px !important;
}
</style>
