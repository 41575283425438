<template>
  <div>
    <div class="mt-4" v-if="!form.allowPublicApiKey && !form.allowPrivateApiKey">
      <span>API Keys are hidden</span>
    </div>
    <div class="vx-row mt-4" v-if="form.allowPublicApiKey || form.allowPrivateApiKey">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Application ID</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <vs-input class="w-full" v-model="applicationId" name="Application ID" :disabled="true"/>
      </div>
    </div>
    <div class="vx-row mt-4" v-if="form.allowPublicApiKey">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Public API Key</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <vs-input class="w-full" v-model="form.publicApiKey" v-validate="'required'" name="Public API Key" :disabled="true"/>
        <span class="text-danger text-sm">{{ errors.first('Public API Key') }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-end" v-if="form.allowPublicApiKey">
      <vs-button 
        @click="regeneratePublicApi" 
        type="border" color="danger" 
        icon-pack="feather"
        icon="icon-refresh-cw" 
        title="Regenerate public api key"
        class="mt-4 mr-4">{{isSmallerScreen ? '': 'Regenerate'}}
      </vs-button>
      <vs-button 
        v-clipboard:copy="form.publicApiKey"
        v-clipboard:success="onCopy" 
        icon-pack="feather" icon="icon-clipboard" class="mt-4"
        title="Copy To Clipboard"
      >{{isSmallerScreen ? '': 'Copy To Clipboard'}}
      </vs-button>
    </div>

    <div class="vx-row mt-12" v-if="form.allowPrivateApiKey">
      <div class="vx-col sm:w-1/5 w-full">
        <span>Private API Key</span>
      </div>
      <div class="vx-col sm:w-4/5 w-full">
        <vs-input class="w-full" v-model="form.privateApiKey" v-validate="'required'" name="Private API Key" :disabled="true" />
        <span class="text-danger text-sm">{{ errors.first('Private API Key') }}</span>
      </div>
    </div>
    <div class="flex flex-wrap justify-end" v-if="form.allowPrivateApiKey">
      <vs-button 
        @click="regeneratePrivateApi" 
        type="border" color="danger" 
        icon-pack="feather"
        icon="icon-refresh-cw" 
        title="Regenerate private api key"
        class="mt-4 mr-4">{{isSmallerScreen ? '': 'Regenerate'}}
      </vs-button>
      <vs-button 
        v-clipboard:copy="form.privateApiKey" 
        v-clipboard:success="onCopy" 
        title="Copy To Clipboard"
        icon-pack="feather" icon="icon-clipboard" class="mt-4">{{isSmallerScreen ? '': 'Copy To Clipboard'}}
      </vs-button>
    </div>

  </div>

  
</template>

<script>
import companyApplicationStore from '../companyApplicationStore'
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'location-settings',
  data () {
    return {
      form: {},
    }
  },
  props: {
    applicationId: { required: true }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
      // If user hasn't gone to the user list yet we need to fetch user list
      // this.$store.dispatch('companyApplicationStore/getGeneralSettings', this.$route.params.companyId )
      //     .catch(err => console.error(err) )
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))
    
    this.form = new Form({
      publicApiKey: appDetailsCopy.publicApiKey,
      privateApiKey: appDetailsCopy.privateApiKey,
      allowPublicApiKey: appDetailsCopy.allowPublicApiKey,
      allowPrivateApiKey: appDetailsCopy.allowPrivateApiKey
    })

  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.put(`api/applications/${this.applicationId}/supportSettings`)
            .then(response => { 
              this.$store.dispatch('companyApplicationStore/updateActiveApplication', this.form.data())
              this.$_notifySuccess('Successfully updated application settings')
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleReset () {
    },
    handleCancel () {
      this.$router.push(`/companies/${this.$route.params.companyId}/apps`)
    },
    regeneratePublicApi () {
      this.$vs.loading()
      this.$http.put(`api/applications/${this.applicationId}/publicApiKey`)
      .then(response => { 
        this.$store.dispatch('companyApplicationStore/updateActiveApplication', { publicApiKey: response.data})
        this.form.publicApiKey = response.data
        this.$_notifySuccess('Public API key successfully regenerated')
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    regeneratePrivateApi () {
      this.$vs.loading()
      this.$http.put(`api/applications/${this.applicationId}/privateApiKey`)
      .then(response => { 
        this.$store.dispatch('companyApplicationStore/updateActiveApplication', { privateApiKey: response.data} )
        this.form.privateApiKey = response.data
        this.$_notifySuccess('Public API key successfully regenerated')
      })
      .catch(error => this.$_notifyFailureByResponseData(error.response.data))
      .finally(() => this.$vs.loading.close())
    },
    onCopy () {
      this.$_notifySuccess('API Key copied to clipboard')
    }
  }
}
</script>
<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }
</style>
