<template>
  <div>
    <feather-icon title="Edit" v-if="true" icon="Edit3Icon" svgClasses="h-4 w-4 hover:text-primary cursor-pointer" @click="editRecord" />
    <!-- <feather-icon title="Copy" icon="CopyIcon" svgClasses="h-4 w-4 ml-2 hover:text-primary cursor-pointer" @click="duplicateImage" /> -->
    <feather-icon title="Delete" v-if="true" icon="TrashIcon" svgClasses="h-4 w-4 ml-2 hover:text-danger cursor-pointer" @click="deleteImage" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CellRendererImageSetActions',
  components: {
  },
  data () {
    return {
      showEditModal: false,
      showDeleteDialog: false,
      deleteAffectedUsersCount: 0,
      deleteHaveAffectedUsers: false,
      selectedReplacementRole: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    editRecord () {
      this.params.context.componentParent.onEditImage(this.params.data, this.params.rowIndex)
    },
    duplicateImage () {
    //  this.params.context.componentParent.onDuplicateImage(this.params.data, this.params.rowIndex)
    },
    deleteImage () {
      this.params.context.componentParent.onDeleteImage(this.params.data, this.params.rowIndex)
    }
  }
}
</script>
