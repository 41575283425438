<template>
  <div class="app-setting-field-select">
    <label class="text-sm">{{ settings.title }}</label>
    <v-select class="mb-4"
      :value="selectedValue"
      @input="updateSettings" 
      :options="options"
      multiple />
    
  </div>
</template>

<script>
export default {
  props: {
    value: { required: true },
    settings: { required: true }
  },
  data () {
    return {
      selectedValue: null
    }
  },
  computed: {
    options () {
      if (!this.settings.options) return []
      
      let serializedOptions = JSON.parse(this.settings.options)
      if(!Array.isArray(serializedOptions)) return []

      return serializedOptions.map(x => ({ label: x.label, code: x.value }))
    }
  },
  mounted () {
    this.selectedValue = this.$_.filter(this.options, x => this.$_.includes(this.value, x.code)) || []
  },
  methods: {
    updateSettings (value) {
      this.selectedValue = value;
      if (value) {
        this.$emit('input', value && JSON.stringify(this.$_.map(value, x => x.code)) )
      } else {
        this.$emit('input', null)
      }
    }
  }
}
</script>

<style>

</style>