<template>
  <div class="app-setting-field-select">
    <label class="text-sm">{{ settings.title }}</label>
       <v-select class="mb-4"
        append-to-body
        :value="selectedValue"
        @input="updateSettings" 
        :options="options"
        :clearable="false" />
  </div>
</template>

<script>
export default {
  props: {
    value: { required: true },
    settings: { required: true }
  },
  data () {
    return {
      selectedValue: null
    }
  },
  computed: {
    options () {
      if (!this.settings.options) return []
      
      let serializedOptions = JSON.parse(this.settings.options)
      if(!Array.isArray(serializedOptions)) return []

      return serializedOptions.map(x => ({ label: x.label, code: x.value }))
    }
  },
  mounted () {
    this.selectedValue = this.$_.find(this.options, x => x.code == this.value) || {}
  },
  methods: {
    updateSettings (value) {
      this.selectedValue = value;
      this.$emit('input', value && value.code)
    }
  }
}
</script>

<style>

</style>