<template>
  <div class="app-setting-field-html">
    <label class="text-sm">{{ settings.title }}</label>
    <div v-if="settings.description" class="text-sm mt-2 mb-2">
      <span v-html="settings.description"></span>
    </div>
    <!-- <quill-editor
      :value="value"
      :options="editorOption"
      @change="onEditorChange"
      @ready="onEditorReady($event)"
    /> -->

    <vs-textarea 
      :value="value"
      rows="4" 
      @change="onTextChange"
      class="w-full"/>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import debounce from 'lodash/debounce'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor,
  },
  props: {
    value: { required: true },
    settings: { required: true }
  },
  data () {
    return {
      sourceView: false,
      editorOption: {
        modules : {
          toolbar : [
            'bold', 'italic', 'underline', 'strike', 'link', { 'list': 'ordered'}, { 'list': 'bullet' }
          ]
        }
      }
    }
  },
  computed: {
  },
  methods: {
    onEditorChange: debounce(function(value) {
      this.$emit('input', value.html)
    }, 466),
    onTextChange (evt) {
      this.$emit('input', evt.target.value)
    },
    onEditorReady(quill) {
    },
  }
}
</script>

<style>
  .app-setting-field-html .ql-editor {
    min-height: 110px !important;
  }
</style>
