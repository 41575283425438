<template>
  <div>
    <vs-input class="w-full mt-4" label="Support Email Label" placeholder="eg. Email Booking Support" v-model="form.supportEmailName" v-validate="'max:50'" name="supportEmailName" />
    <span class="text-danger text-sm">{{ errors.first('supportEmailName') }}</span>

    <vs-input class="w-full mt-4" label="Support Email Address" placeholder="eg. bookings@domain.com" v-model="form.supportEmail" v-validate="'max:100|email'" name="supportEmail" />
    <span class="text-danger text-sm">{{ errors.first('supportEmail') }}</span>

    
    <vs-input class="w-full mt-4" label="Support Telephone Label" placeholder="eg. Call Booking Support" v-model="form.supportTelephoneName" v-validate="'max:150'" name="supportTelephoneName" />
    <span class="text-danger text-sm">{{ errors.first('supportTelephoneName') }}</span>

    <vs-input class="w-full mt-4" label="Support Telephone Number" placeholder="eg. 0123456789" v-model="form.supportTelephone" v-validate="'max:20'" name="supportTelephone" />
    <span class="text-danger text-sm">{{ errors.first('supportTelephone') }}</span>

    <div class="lg:float-left mt-4">
      <span class="text-sm text-danger">*Required Field</span>
    </div>
    <div class="flex flex-wrap justify-end">
      <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
    </div>
  </div>
</template>

<script>
import companyApplicationStore from '../companyApplicationStore'
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'location-settings',
  data () {
    return {
      form: {},
    }
  },
  props: {
    applicationId: { required: true }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
      // If user hasn't gone to the user list yet we need to fetch user list
      // this.$store.dispatch('companyApplicationStore/getGeneralSettings', this.$route.params.companyId )
      //     .catch(err => console.error(err) )
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))
    
    this.form = new Form({
      supportEmailName: appDetailsCopy.supportEmailName,
      supportEmail: appDetailsCopy.supportEmail,
      supportTelephoneName: appDetailsCopy.supportTelephoneName,
      supportTelephone: appDetailsCopy.supportTelephone,
    })

  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.put(`api/applications/${this.applicationId}/supportSettings`)
            .then(response => { 
              this.$store.dispatch('companyApplicationStore/updateActiveApplication', this.form.data())
              this.$_notifySuccess('Successfully updated application settings')
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleReset () {
    },
    handleCancel () {
      this.$router.push(`/companies/${this.$route.params.companyId}/apps`)
    }
  }
}
</script>
<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }
</style>
