<template>
  <div>
    <div class="flex flex-wrap items-center mt-4">
      <vs-avatar :src="form.logoUrl" icon="cloud_upload" @click="$refs.companyLogoInput.click()" size="70px" class="mr-4 mb-4 company-logo-avatar" />
      <div>
        <input type="file" hidden id="companyLogoInput" ref="companyLogoInput" v-on:change="handleFileUpload"/>
        <vs-button @click="$refs.companyLogoInput.click()" class="mr-4 sm:mb-0 mb-2">Upload App Logo</vs-button>
        <vs-button @click="removeLogo" type="border" color="danger">Remove</vs-button>
        <p class="text-sm mt-2">Allowed JPG, GIF or PNG. Max size of 800kB</p>
      </div>
    </div>
    
    <vs-input class="w-full mt-2" label="Application Name*" v-model="form.applicationName" v-validate="'max:50'" name="applicationName" />
    <vs-input class="w-full mt-2" label="System Notifications Sent To" v-model="form.systemNotificationsSentTo" v-validate="'max:200|email'" name="System Notifications Sent To" />
    <span class="text-danger text-sm">{{ errors.first('System Notifications Sent To') }}</span>
    <div class="mt-4">
      <label class="text-sm">Data Centre Region*</label>
      <v-select :value="selectedRegion" :disabled="true" append-to-body @input="setSelectedRegion" :options="regionOptions" v-validate="'required'" name="Region" />
      <span class="text-danger text-sm">{{ errors.first('Region') }}</span>
    </div>
    <div class="mt-4">
      <label class="text-sm">Web Platform</label>
      <v-select :value="selectedWebPlatform" append-to-body @input="setSelectedWebPlatform" :options="webPlatformOptions" />
    </div>
    <div class="mt-4 mb-8">
      <label class="text-sm">Subscription*</label>
      <v-select :value="selectedSubscription" :disabled="true" append-to-body @input="setSelectedSubscription" :options="subscriptionOptions" />
    </div>

    <div class="lg:float-left mt-4">
      <span class="text-sm text-danger">*Required Field</span>
    </div>
    <div class="flex flex-wrap justify-end">
      <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
    </div>
  </div>
</template>

<script>
import companyApplicationStore from '../companyApplicationStore'
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'general-settings',
  data () {
    return {
      form: {},
      selectedSubscription: null,
      selectedWebPlatform: null,
      logoFile: null,
      selectedRegion: null
    }
  },
  props: {
    applicationId: { required: true }
  },
  computed: {
    subscriptionOptions () {
      return this.$store.state.companyApplicationStore.subscriptionOptions
    },
    webPlatformOptions () {
      return this.$store.state.companyApplicationStore.webPlatformOptions
    },
    regionOptions () {
      return this.$store.state.companyApplicationStore.regionOptions
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
      // If user hasn't gone to the user list yet we need to fetch user list
      // this.$store.dispatch('companyApplicationStore/getGeneralSettings', this.$route.params.companyId )
      //     .catch(err => console.error(err) )
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))
    this.form = new Form({
      applicationName: appDetailsCopy.applicationName,
      systemNotificationsSentTo: appDetailsCopy.systemNotificationsSentTo,
      subscriptionId: appDetailsCopy.subscriptionId,
      webPlatformId: appDetailsCopy.webPlatformId,
      regionId: appDetailsCopy.regionId,
      logoUrl: appDetailsCopy.logoUrl
    })

    this.selectedSubscription = this.subscriptionOptions.find(x => x.code == appDetailsCopy.subscriptionId) || {};
    this.selectedWebPlatform = this.webPlatformOptions.find(x => x.code == appDetailsCopy.webPlatformId) || {};
    this.selectedRegion = this.regionOptions.find(x => x.code == appDetailsCopy.regionId) || {};

  },
  beforeDestroy () {

    this.handleReset()
  },
  methods: {
    setSelectedSubscription (value) {
      this.form.subscriptionId = value ? value.code : null
      this.selectedSubscription = value;
    },
    setSelectedWebPlatform (value) {
      this.form.webPlatformId = value ? value.code : null
      this.selectedWebPlatform = value;
    },
    setSelectedRegion (value) {
      this.form.regionId = value ? value.code : null
      this.selectedRegion = value;
    },
    handleFileUpload () {
      this.logoFile = this.$refs.companyLogoInput.files[0];
      if (this.logoFile) {
        let formData = new FormData();
        formData.append('logoFile', this.logoFile)
        this.$vs.loading()
        this.$http.put(`api/applications/${this.applicationId}/logos`, formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
        })
        .then(response => {
          this.form.logoUrl = response.data.logoUrl
          this.$store.dispatch('companyApplicationStore/updateActiveApplication', { logoUrl: this.form.logoUrl })
          this.$_notifySuccess('Logo successfully uploaded')
        })
        .catch(error => this.$_notifyFailureByResponseData(error.response.data))
        .finally(() => this.$vs.loading.close() )
      }
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.put(`api/applications/${this.applicationId}/generalSettings`)
            .then(response => { 
              this.$store.dispatch('companyApplicationStore/updateActiveApplication', this.form.data())
              this.$_notifySuccess('Successfully updated application settings')
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleReset () {
      // this.form = new Form(this.form.originalData)
    },
    handleCancel () {
      this.$router.push(`/companies/${this.$route.params.companyId}/apps`)
    },
    removeLogo () {
      this.$refs.companyLogoInput.value = '';
      this.form.logoUrl = '';
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }
</style>
