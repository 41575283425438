<template>
  <div>
    <div v-for="(category, index) in Object.keys(categorizedSettings)" :key="index">
      <h3 class="text-primary mt-5 mb-1">{{category}}</h3>
      <hr size="1">
      <div class="mt-4" 
        v-for="(formField, index) in categorizedSettings[category]" 
        :key="index">
          <component 
            :is="formField.component" 
            v-model="formField.value"
            v-bind="{ settings: formField.settings }">
          </component>
      </div>
    </div>
    <div class="flex flex-wrap justify-end">
      <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
    </div>
  </div>
</template>

<script>
import companyApplicationStore from '../companyApplicationStore'
import flatPickr from 'vue-flatpickr-component'

import AppSettingFieldArraylinks from '../components/field-settings/AppSettingFieldArraylinks.vue'
import AppSettingFieldColor from '../components/field-settings/AppSettingFieldColor.vue'
import AppSettingFieldHtml from '../components/field-settings/AppSettingFieldHtml.vue'
import AppSettingFieldSelect from '../components/field-settings/AppSettingFieldSelect.vue'
import AppSettingFieldText from '../components/field-settings/AppSettingFieldText.vue'
import AppSettingFieldUrl from '../components/field-settings/AppSettingFieldUrl.vue'
import AppSettingFieldCheckbox from '../components/field-settings/AppSettingFieldCheckbox.vue'



export default {
  name: 'generic-settings',
  components: {
    AppSettingFieldArraylinks,
    AppSettingFieldColor,
    AppSettingFieldHtml,
    AppSettingFieldSelect,
    AppSettingFieldText,
    AppSettingFieldText,
    AppSettingFieldUrl,
    AppSettingFieldCheckbox
  },
  data () {
    return {
      categorizedSettings: {}
    }
  },
  props: {
    applicationId: { required: true },
    settingSectionId : { default: 0}
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))

    const genericSettings = appDetailsCopy.genericSettings
      .filter(s => s.settingSectionId == this.settingSectionId)
      .map(x => {
        return {
          category: x.settingCategory,
          component: 'app-setting-field-' + x.settingType,
          type: x.settingType,
          value: x.settingValue,  
          settings: {
            title: x.settingTitle,
            name: x.settingName,
            options: x.settingOptions,
            orderId: x.settingOrderId
          }
        }
      })
    this.categorizedSettings = this.$_.groupBy(genericSettings, setting => setting.category )
  },
  methods: {
    handleSubmit () {
      this.$validator.validateAll()
      .then(result => {
        if(result) {
          this.$vs.loading()
          let flattenSettings = []
          this.$_.forEach(Object.keys(this.categorizedSettings), x => {
            flattenSettings = [...flattenSettings, ...this.categorizedSettings[x]]
          })
          const settingValues = flattenSettings.map(x => ({
            settingName: x.settings.name,
            // null not allowed in db
            settingValue: x.value || "" })
          )

          this.$http.put(`api/applications/${this.applicationId}/genericSettings`, settingValues)
          .then(response => { 
            // Update app settings stored in vuex
            this.$emit('updateAppSettings')
            this.$_notifySuccess('Application settings successfully updated.')
          })
          .catch(error => this.$_notifyFailureByResponseData(error.response.data))
          .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid input on some fields, please fill fields with valid format.')
        }
      })
      .catch(error => this.$_notifyFailure('Invalid input on some fields, please fill fields with valid format.'))
    },
    handleCancel () {
      // this.$router.push(`/companies/${this.$route.params.companyId}/apps`)
    }
  }
}
</script>
<style>

</style>
