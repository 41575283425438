<template>
  <travio-modal v-show="showAppReferrerDialog" width='430' height="100" zIndex="99999">
    <h3 slot="header">App Referrer</h3>
    <div slot="body">
      <div class="vx-row mt-2">
        <div class="vx-col w-full">
          <label class="">Host</label>

          <vs-input v-if="isPrivate" v-validate="'required|mustBeValidIpAddress|noNewLine'"
            name="Host"
            type="text"
            v-model="hostValue"
            class="w-full" />
          <span v-if="isPrivate" class="text-danger text-sm">{{ errors.first('Host')}}</span>
          
          <vs-input v-if="!isPrivate" v-validate="'required|mustBeValidDomainName|noNewLine'"
            name="Host"
            type="text"
            v-model="hostValue"
            class="w-full" />
          <span v-if="!isPrivate" class="text-danger text-sm">{{ errors.first('Host')}}</span>

        </div>
      </div>
    </div>
    <div slot="footer" class="flex flex-wrap justify-center">
      <vs-button :disabled="errors.any()" color="primary" @click="save" type="filled">Save</vs-button>
      <vs-button class="ml-2" color="danger" @click="close" type="border">Cancel</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import { Validator } from 'vee-validate';


Validator.extend('mustBeValidDomainName', {
  getMessage: field => field + ' must be valid domain name.',
  validate: value => {
    // https://regex101.com/r/Vi2CYC/1
    var domainNameRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,20})+$/
    return domainNameRegex.test(value)
  }
});

Validator.extend('mustBeValidIpAddress', {
  getMessage: field => field + ' must be valid IPV4 or IPV6 address.',
  validate: value => {
    // https://www.geeksforgeeks.org/how-to-validate-an-ip-address-using-regex/
    let ipv4 = /(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/;
 
    // Regex expression for validating IPv6
    let ipv6 = /((([0-9a-fA-F]){1,4})\:){7}([0-9a-fA-F]){1,4}/;
    
    let ipv4And6 = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
    return ipv4And6.test(value)
  }
});

Validator.extend('noNewLine', {
  getMessage: field => field + ' must not include new line.',
  validate: value => {
    // https://stackoverflow.com/questions/49680496/vuelidate-validation-for-comma-seperated-list-of-multiple-emails
    return !(/\n/.test(value))
  }
});

export default {
  components: {
    TravioModal,
  },
  data () {
    return {
      hostValue: null,
      showAppReferrerDialog: false,
    }
  },
  props: {
    applicationId: { required: true},
    host: { type: String},
    isPrivate: { type: Boolean, required: true},
    isEditMode: { type: Boolean, required: true},
  },
  computed: {
   
  },
  async mounted (){
    await this.launchReferrerDialog();
  },
  methods: {
    launchReferrerDialog() {
      this.showAppReferrerDialog = true
      this.hostValue = this.host
      
    },
    async save() {
      if(this.isEditMode) {
        this.$emit('onSuccessEdit', { oldHost: this.host, newHost: this.hostValue, isPrivate: this.isPrivate })
      } else {
        this.$emit('onSuccessAdd', { host: this.hostValue, isPrivate: this.isPrivate })
      }
      
    },
    close () {
      this.$emit('onCancel')
    }
  }
  
}
</script>

<style>
</style>
