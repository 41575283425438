<template>
  <div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="appReferrers"
      :gridOptions="gridOptions"
      domLayout="normal"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :style="{ height:'232px'}"
      :context="context"
      @grid-size-changed="onGridSizeChanged"
    ></ag-grid-vue>
  </div>
  
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererAppRefererActions from "../cell-renderers/CellRendererAppRefererActions.vue";

export default {
  components: {
    AgGridVue,
    CellRendererAppRefererActions
  },
  props: {
    appReferrers: { type: Array, required: true },
    isPrivate: { type: Boolean, required: true}
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      context: null,
      cellRendererComponents: {
        CellRendererAppRefererActions
      },
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { hide: true, field: 'isPrivate', },
      { headerName: 'Host', field: 'host', suppressSizeToFit: false, sortable: false, width: 200 },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAppRefererActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }

    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    onGridSizeChanged(params) {
      if (params && params.api) {
        params.api.sizeColumnsToFit();
      }
    },
    editReferrer(evt) {
      this.$emit('onEditReferrer', evt)
    },
    deleteReferrer(evt) {
      this.$emit('onDeleteReferrer', evt)
    },
  }
}
</script>

<style>

</style>