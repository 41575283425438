<template>
  <div class="app-setting-field-text">
    <label class="text-sm">{{ settings.title }}</label>
    <input
      :value="value"
      type="text"
      class="w-full text-sm" 
      placeholder=""
      @input="updateSettings($event.target.value)">
  </div>
</template>

<script>
export default {
  props: {
    value: { required: true },
    settings: { required: true }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    updateSettings (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>
.app-setting-field-text input {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    
}
.app-setting-field-text input:focus {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(var(--vs-primary),1)!important;
}

</style>