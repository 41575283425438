<template>
  <div>
    <vs-input class="w-full mt-2" label="Address 1" v-model="form.address1" v-validate="'max:250'" name="address1" />
    <span class="text-danger text-sm">{{ errors.first('address1') }}</span>
    <vs-input class="w-full mt-4" label="Address 2" v-model="form.address2" v-validate="'max:250'" name="address2" />
    <span class="text-danger text-sm">{{ errors.first('address2') }}</span>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vs-input class="w-full mt-4" label="City/Town/Area" v-model="form.city" v-validate="'max:50'" name="city" />
        <span class="text-danger text-sm">{{ errors.first('city') }}</span>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vs-input class="w-full mt-4" label="State/Postal Code" v-model="form.state" v-validate="'max:50'" name="state" />
        <span class="text-danger text-sm">{{ errors.first('state') }}</span>
      </div>
    </div>

    <!-- Country -->
    <div class="mt-4">
      <label class="text-sm">Country</label>
      <v-select :value="selectedCountry" append-to-body @input="setSelectedCountry" :options="countryOptions" />
    </div>

    <div class="lg:float-left mt-4">
      <span class="text-sm text-danger">*Required Field</span>
    </div>
    <div class="flex flex-wrap justify-end">
      <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
    </div>
  </div>
</template>

<script>
import companyApplicationStore from '../companyApplicationStore'
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'location-settings',
  data () {
    return {
      form: {},
      selectedCountry: null
    }
  },
  props: {
    applicationId: { required: true }
  },
  computed: {
    countryOptions () {
      return this.$store.state.lookups.countryOptions
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
      // If user hasn't gone to the user list yet we need to fetch user list
      // this.$store.dispatch('companyApplicationStore/getGeneralSettings', this.$route.params.companyId )
      //     .catch(err => console.error(err) )
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))
    
    this.form = new Form({
      address1: appDetailsCopy.address1,
      address2: appDetailsCopy.address2,
      city: appDetailsCopy.city,
      state: appDetailsCopy.state,
      countryCode: appDetailsCopy.countryCode
    })

    this.selectedCountry = this.countryOptions.find(x => x.code == appDetailsCopy.countryCode) || {};

  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    setSelectedCountry (value) {
      this.form.countryCode = value ? value.code : null
      this.selectedCountry = value;
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.put(`api/applications/${this.applicationId}/locationSettings`)
            .then(response => { 
              this.$store.dispatch('companyApplicationStore/updateActiveApplication', this.form.data())
              this.$_notifySuccess('Successfully updated application settings')
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleReset () {
      this.selectedCountry = null
      this.form = new Form(this.form.originalData)
    },
    handleCancel () {
      this.$router.push(`/companies/${this.$route.params.companyId}/apps`)
    }
  }
}
</script>

<style>
  .company-logo-avatar .con-vs-avatar,
  .company-logo-avatar .vs-avatar--con-img {
    border-radius: 10%;
  }

  /* Fix date picker issue month selection partially hidden*/
  .flatpickr-months .flatpickr-month {
    overflow: initial;
  }
</style>
