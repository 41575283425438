<template>
  <div class="p-4">
    <h5 class="muted">Public Referrers (Widgets)</h5>
    <vs-button class="mt-2" size="small" color="primary" @click="showAddReferrerlDialog(false)" type="filled">Add</vs-button>      
    <div class="p-4">
      <security-settings-app-referrers 
      :appReferrers="publicReferrers" 
      :isPrivate="false"
      @onEditReferrer="handleEditReferrer"
      @onDeleteReferrer="handleDeleteReferrer"
       />    
    </div>

    <h5 v-if="appAllowPrivate" class="mt-4 muted">Private Referrers (API Access)</h5>
    <vs-button v-if="appAllowPrivate" class="mt-2" size="small" color="primary" @click="showAddReferrerlDialog(true)" type="filled">Add</vs-button>      
    <div v-if="appAllowPrivate" class="p-4">
      <security-settings-app-referrers 
        :appReferrers="privateReferrers" 
        :isPrivate="true"
        @onEditReferrer="handleEditReferrer"
        @onDeleteReferrer="handleDeleteReferrer"
        />    
    </div>

    <app-referrers-form-dialog v-if="showReferrerDialog" 
      :applicationId="applicationId"
      :host="hostForEdit" 
      :isPrivate="isPrivateForEdit"
      :isEditMode="isEditMode"
      @onSuccessAdd="handleSuccessAdd"
      @onSuccessEdit="handleSuccessEdit"
      @onCancel="showReferrerDialog=false" />
</div>
</template>

<script>
import companyApplicationStore from '../companyApplicationStore'
import flatPickr from 'vue-flatpickr-component'
import { Form } from '@/core-modules/form-framework/Form.js'
import SecuritySettingsAppReferrers from './SecuritySettingsAppReferrers.vue'
import AppReferrersFormDialog from './AppReferrersFormDialog.vue'

export default {
  name: 'security-settings',
  components: {
    SecuritySettingsAppReferrers,
    AppReferrersFormDialog,
  },
  data () {
    return {
      form: {},
      publicReferrers: [],
      privateReferrers: [],
      appAllowPrivate: false,
      showReferrerDialog: false,
      hostForEdit: null,
      isPrivateForEdit: false,
      isEditMode: false
    }
  },
  props: {
    applicationId: { required: true }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true
      // If user hasn't gone to the user list yet we need to fetch user list
      // this.$store.dispatch('companyApplicationStore/getGeneralSettings', this.$route.params.companyId )
      //     .catch(err => console.error(err) )
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))
    this.appAllowPrivate = appDetailsCopy.allowPrivate
    this.privateReferrers = appDetailsCopy.privateReferrers.map(x => ({ host: x, isPrivate: true}))
    this.publicReferrers = appDetailsCopy.publicReferrers.map(x => ({ host: x, isPrivate: false}))
  },
  beforeDestroy () {
    this.handleReset()
  },
  methods: {
    showAddReferrerlDialog(isPrivate) {
      this.hostForEdit = null
      this.isPrivateForEdit = isPrivate
      this.isEditMode = false
      this.showReferrerDialog = true
    },
    async handleSuccessAdd (evt) {
      this.hostForEdit = null
      this.showReferrerDialog = false

      this.$vs.loading()

      try {
        await this.$http.post(`api/applications/${this.applicationId}/referrers`, { 
          host: evt.host,
          isPrivate: evt.isPrivate
        })
        if (evt.isPrivate) {
          this.privateReferrers.push({ host: evt.host, isPrivate: true})
        } else {
          this.publicReferrers.push({ host: evt.host, isPrivate: false})
        }

        this.$_notifySuccess('Successfully added referrer')
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()

    },
    async handleSuccessEdit (evt) {
      this.hostForEdit = null
      this.showReferrerDialog = false

      this.$vs.loading()

      try {
        await this.$http.put(`api/applications/${this.applicationId}/referrers`, { 
          oldHost: evt.oldHost,
          newHost: evt.newHost,
          isPrivate: evt.isPrivate
        })
        if (evt.isPrivate) {
          const privateReferrer = this.privateReferrers.find(x => x.host == evt.oldHost)
          privateReferrer.host = evt.newHost
          this.privateReferrers = [...this.privateReferrers ]
        } else {
          const publicReferrer = this.publicReferrers.find(x => x.host == evt.oldHost)
          publicReferrer.host = evt.newHost
          this.publicReferrers = [...this.publicReferrers ]
        }

        this.$_notifySuccess('Successfully updated referrer')
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    handleEditReferrer (evt) {
      this.hostForEdit = evt.host
      this.isPrivateForEdit = evt.isPrivate
      this.isEditMode = true
      this.showReferrerDialog = true
    },

    async handleDeleteReferrer (evt) {

      try {

        await this.$http.delete(`api/applications/${this.applicationId}/referrers?host=${evt.host}&isPrivate=${evt.isPrivate}`)

        if (evt.isPrivate) {
          this.privateReferrers = this.privateReferrers.filter(x => x.host != evt.host)
          this.privateReferrers = [...this.privateReferrers ]
        } else {
          this.publicReferrers = this.publicReferrers.filter(x => x.host != evt.host)
          this.publicReferrers = [...this.publicReferrers ]
        }
        this.$_notifySuccess('Successfully deleted referrer')

      } catch (error) {
        this.$_notifyFailure(error)
      }

      this.$vs.loading.close()
    }
  }
}
</script>
<style>
  
</style>
