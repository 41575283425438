<template>
  <travio-center-container grid-width="2/3" pageTitle="Application Settings">
    <vx-card v-if="!dataFetching">
        <vs-tabs v-if="activeApplication" position="left" v-model='selectedTabIndex' class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
          <!-- GENERAL -->
          
          <vs-tab icon-pack="feather" icon="icon-settings" :label="!isSmallerScreen ? 'General' : ''">
            <div class="tab-general md:ml-0 md:mt-0 mt-0 ml-0">
              <general-settings :applicationId="applicationId" />
            </div>
          </vs-tab>

          <!-- LOCATION INFO -->
          <vs-tab icon-pack="feather" icon="icon-map-pin" :label="!isSmallerScreen ? 'Location' : ''">
            <div class="tab-general md:mt-0 mt-0 ml-0">
              <location-settings :applicationId="applicationId" />
            </div>
          </vs-tab>

          <!-- BOOKING -->
          <vs-tab icon-pack="feather" icon="icon-calendar" :label="!isSmallerScreen ? 'Booking Contacts' : ''">
            <div class="tab-general md:mt-0 mt-4 ">
              <booking-settings :applicationId="applicationId" />
            </div>
          </vs-tab>

          <!-- SUPPORT -->
          <vs-tab icon-pack="feather" icon="icon-info" :label="!isSmallerScreen ? 'Support Contacts' : ''">
            <div class="tab-general md:mt-0 mt-4">
              <support-settings :applicationId="applicationId" />
            </div>
          </vs-tab>

          <!-- Regional Settings -->
          <vs-tab icon-pack="feather" icon="icon-globe" :label="!isSmallerScreen ? 'Regional Settings' : ''">
            <div class="tab-general md:mt-0 mt-0 ml-0">
              <regional-settings :applicationId="applicationId" />
            </div>
          </vs-tab>

          <!-- Application Settings -->
         <vs-tab icon-pack="feather" icon="icon-more-vertical" :label="!isSmallerScreen ? 'General Settings' : ''">
           <div class="tab-general md:mt-0 mt-0 ml-0">
             <generic-settings :applicationId="applicationId" @updateAppSettings="reloadCurrentAppSettings"/>
           </div>
         </vs-tab>

          <!-- Email Settings -->
          <vs-tab icon-pack="feather" icon="icon-more-vertical" :label="!isSmallerScreen ? 'Email Settings' : ''">
            <div class="tab-general md:mt-0 mt-0 ml-0">
              <generic-settings :applicationId="applicationId" :setting-section-id="1" @updateAppSettings="reloadCurrentAppSettings"/>
            </div>
          </vs-tab>


          <!-- API KEYS -->
          <vs-tab icon-pack="feather" icon="icon-code" :label="!isSmallerScreen ? 'API Keys' : ''">
            <div class="tab-general md:mt-0 mt-4">
              <api-key-settings :applicationId="applicationId" />
            </div>
          </vs-tab>

          <!-- SECURITY -->
          <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? 'Security' : ''">
            <div class="tab-general md:mt-0 mt-4">
              <security-settings :applicationId="applicationId" />
            </div>
          </vs-tab>

        </vs-tabs>

    </vx-card>
  </travio-center-container>
</template>

<script>
import companyApplicationStore from './companyApplicationStore.js'
import flatPickr from 'vue-flatpickr-component'
import GeneralSettings from './components/GeneralSettings.vue'
import LocationSettings from './components/LocationSettings.vue'
import BookingSettings from './components/BookingSettings.vue'
import SupportSettings from './components/SupportSettings.vue'
import ApiKeySettings from './components/ApiKeySettings.vue'
import RegionalSettings from './components/RegionalSettings.vue'
import GenericSettings from './components/GenericSettings.vue'
import SecuritySettings from './components/SecuritySettings.vue'

export default {
  components: {
    GeneralSettings,
    LocationSettings,
    BookingSettings,
    SupportSettings,
    ApiKeySettings,
    RegionalSettings,
    GenericSettings,
    SecuritySettings
  },
  props: {
    applicationId: { required: true },
    companyId: { required: true }
  },
  data () {
    return {
      selectedTabIndex: 0,
      dataFetching: true
    }
  },
  computed: {
    activeApplication () {
      return this.$store.state.companyApplicationStore.activeApplication;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  created () {
    if (!companyApplicationStore.isRegistered) {
      this.$store.registerModule('companyApplicationStore', companyApplicationStore)
      companyApplicationStore.isRegistered = true

      // If this page is bookmarked then we need to load the store
      this.$store.dispatch('companyApplicationStore/fetchApplications', this.companyId )
        .catch(err => console.error(err) )
    }
  },
  mounted () {
    
    if(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) {
      
      //Check if user has acccess to the app
      this.$vs.loading();
      this.$store.dispatch('companyApplicationStore/fetchLookups',{ 
        companyId: this.companyId, applicationId: this.applicationId
      }).then(response => {
        this.reloadCurrentAppSettings()  
      })

    } else {
      this.$router.push('/error-404')
    }

    
  },
  methods: {
    reloadCurrentAppSettings() {
      this.$store.dispatch('companyApplicationStore/fetchApplicationDetails',{ 
        companyId: this.companyId, applicationId: this.applicationId
      })
      .then(response => {
        this.dataFetching = false
      })
      .catch(err => console.error(err) )
      .finally(()=> this.$vs.loading.close());
    }
  }
}
</script>
