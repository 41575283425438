<template>
  <div class="app-setting-field-url">
    <label class="text-sm">{{ settings.title }}</label>
    <input
      :value="value"
      type="text"
      class="w-full text-sm" 
      :name="settings.title"
      v-validate="{url: {require_protocol: false }}"
      @input="updateSettings($event.target.value)">
      <span class="text-danger text-sm">{{ errors.first(settings.title) }}</span>
  </div>

</template>

<script>
export default {
  props: {
    value: { required: true },
    settings: { required: true }
  },
  // this will make this component inherit the validation scope of the parent
  inject: {
    $validator: '$validator'
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    updateSettings (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>
.app-setting-field-url input {
    padding: .7rem;
    font-size: 1rem !important;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    font-family: inherit;
    color:inherit;
    
}
.app-setting-field-url input:focus {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(var(--vs-primary),1)!important;
}

</style>