<template>
  <div>
    <div class="mt-4">
      <label class="text-sm">Currencies - must also be supported by your payment provider(s)</label>
      <v-select :value="selectedCurrencies" append-to-body multiple @input="setSelectedCurrencies" :options="currencyOptions" />
    </div>
    <div class="mt-4">
      <label class="text-sm">Languages</label>
      <v-select :value="selectedLanguages" append-to-body multiple @input="setselectedLanguages" :options="languageOptions" />
    </div>
    <div class="mt-4">
      <label class="text-sm">Nationalities</label>
      <v-select :value="selectedNationalities" append-to-body multiple @input="setSelectedNationalities" :options="nationalityOptions" />
    </div>

    <div class="flex flex-wrap justify-end">
      <vs-button @click="handleCancel" type="border" color="danger" class="mt-4 mr-4">Cancel</vs-button>
      <vs-button @click="handleSubmit" class="mt-4">Save</vs-button>
    </div>
  </div>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  name: 'regional-settings',
  data () {
    return {
      form: {},
      selectedCurrencies: null,
      selectedLanguages: null,
      selectedNationalities: null
    }
  },
  props: {
    applicationId: { required: true }
  },
  computed: {
    languageOptions () {
      return this.$store.state.companyApplicationStore.languageOptions
    },
    currencyOptions () {
      return this.$store.state.companyApplicationStore.currencyOptions
    },
    nationalityOptions () {
      return this.$store.state.companyApplicationStore.nationalityOptions
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  mounted () {
    const details = this.$store.state.companyApplicationStore.activeApplication
    const appDetailsCopy = JSON.parse(JSON.stringify(details))

    this.form = new Form({
      currencies: appDetailsCopy.currencies,
      languages: appDetailsCopy.languages,
      nationalities: appDetailsCopy.nationalities
    })

    this.selectedCurrencies = this.currencyOptions.filter(x => {
      return appDetailsCopy.currencies && appDetailsCopy.currencies.includes(x.code)
    });

    this.selectedLanguages = this.languageOptions.filter(x => {
      return appDetailsCopy.languages && appDetailsCopy.languages.includes(x.code)
    });

    this.selectedNationalities = this.nationalityOptions.filter(x => {
      return appDetailsCopy.nationalities && appDetailsCopy.nationalities.includes(x.code)
    });
    // this.selectedWebPlatform = this.webPlatformOptions.find(x => x.code == appDetailsCopy.webPlatformId) || {};
    // this.selectedRegion = this.regionOptions.find(x => x.code == appDetailsCopy.regionId) || {};

  },
  beforeDestroy () {

    this.handleReset()
  },
  methods: {
    setSelectedCurrencies (value) {
      this.selectedCurrencies = value;
      this.form.currencies = this.selectedCurrencies.length
        ? this.selectedCurrencies.map((item) => item.code)
        : []
    },
    setselectedLanguages (value) {
      this.selectedLanguages = value;
      this.form.languages = this.selectedLanguages.length
        ? this.selectedLanguages.map((item) => item.code)
        : []
    },
    setSelectedNationalities (value) {
      this.selectedNationalities = value;
      this.form.nationalities = this.selectedNationalities.length
        ? this.selectedNationalities.map((item) => item.code)
        : []
    },
    handleSubmit () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          this.form.put(`api/applications/${this.applicationId}/regionalSettings`)
            .then(response => { 
              this.$store.dispatch('companyApplicationStore/updateActiveApplication', this.form.data())
              this.$_notifySuccess('Successfully updated application settings')
            })
            .catch(error => this.$_notifyFailureByResponseData(error.response.data))
            .finally(() => this.$vs.loading.close())
        } else {
          this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        }
      })
    },
    handleReset () {
      // this.form = new Form(this.form.originalData)
    },
    handleCancel () {
      this.$router.push(`/companies/${this.$route.params.companyId}/apps`)
    }
  }
}
</script>

<style>
 
</style>
