<template>
  <div class="app-setting-field-color mt-6">
    <label class="text-sm">{{ settings.title }}</label>
    <button 
      @click="showColorPicker=true"
      class="button-picker w-full" 
      :style="{ border: '1px solid ' + textColor, backgroundColor: colorValue }"
    >
      <span :style="{ color: textColor }">SET COLOUR</span>
    </button>

    <travio-modal v-if="showColorPicker" width='350' height="420">
      <div slot="body">
        <Chrome :value="colorValue"  @input="updateSettings"
          :preset-colors="[ 
            '#f00', '#00ff00', '#00ff0055', 'rgb(201, 76, 76)', 'rgba(0,0,255,1)', 'hsl(89, 43%, 51%)', 'hsla(89, 43%, 51%, 0.6)'
          ]"
        ></Chrome>
        <Swatches :value="colorValue"  @input="updateSettings" />
      </div>
      <div slot="footer" class="flex flex-wrap justify-center">
        <vs-button class="w-full" color="primary" @click="onSave" type="filled">OK</vs-button>      
      </div>
    </travio-modal>

  </div>
  
</template>

<script>
import { Chrome, Swatches } from 'vue-color'
import invert from 'invert-color';
import TravioModal from '@/components/travio-pro/TravioModal.vue'

export default {
  components: {
    TravioModal,
    Chrome,
    Swatches
  },
  props: {
    value: { required: true },
    settings: { required: true }
  },
  computed: {
  },
  data () {
    return {
      textColor: '#FFFFFF',
      colorValue: '#000000',
      showColorPicker: false
    }
  },
  mounted () {
    if (this.value) {
      this.colorValue = this.value
      // Color value is in hex8 format, which contains transparency value - e.g. #FF00AA0.25
      this.textColor = invert(this.value.substring(0,7))
    }
    
  },
  methods: {
    updateSettings (value) {
      // console.log('hex: ' + value.hex + ' hex8: ' + value.hex8)
      this.colorValue = value.hex8
      this.textColor = invert(value.hex || '#000000')
      this.$emit('input', value.hex8)
    },
    onSave (value) {
      this.showColorPicker = false
    }
  }
}
</script>

<style>
.app-setting-field-color .vc-chrome {
  width:100%;
  box-shadow: none;
}

.app-setting-field-color .vc-swatches {
  width:100%;
  height: 115px !important;
  box-shadow: none;
}

.app-setting-field-color .vc-input__input {
  height: 30px !important;
  font-size: 15px;
  border-radius: 3px;
}

.app-setting-field-color .vc-swatches-box {
  padding: 0
}

.app-setting-field-color .vs-divider {
  margin-top: 0px;
}

.app-setting-field-color .button-picker {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    padding: 10px;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #fff;
    box-sizing: border-box;
    font-size: 1.1rem;
}
</style>